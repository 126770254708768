import { createApp } from 'vue'
import App from './app.vue'
import router from './router';

import firebase from 'firebase';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import '@/theme/variables.css';

// Init
firebase.initializeApp({
  apiKey: 'AIzaSyCfcIHxYhpLjPWoTitPv6LnEVeGvFBJG_k',
  authDomain: 'project-release-2b33c.firebaseapp.com',
  projectId: 'project-release-2b33c',
  storageBucket: 'project-release-2b33c.appspot.com',
  messagingSenderId: '491121039338',
  appId: '1:491121039338:web:b2bed348c189c230a64493'
});

const app = createApp(App)
  .use(IonicVue)
  .use(router);

router.isReady().then(() => {
  app.mount('#app');
});
