import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    component: () => import ('@/views/home/home.vue'),
    children: [
      {
        path: 'latest-releases',
        component: () => import('@/views/latest-releases/latest-releases.vue'),
      },
      {
        path: 'package/:packageId',
        component: () => import('@/views/package-info/package-info.vue'),
      },
      {
        path: 'package/:packageId/:releaseId',
        component: () => import('@/views/package-info/package-info.vue'),
      },
      {
        path: 'packages',
        component: () => import('@/views/packages/packages.vue'),
        children: [
          {
            path: ':registry',
            component: () => import('@/components/package-list/package-list.vue'),
          },
        ]
      },
      {
        path: '',
        redirect: '/home/latest-releases'
      },
    ],
  },
  {
    path: '/login',
    component: () => import ('@/views/login/login.vue'),
  },
  {
    path: '',
    redirect: '/home'
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
