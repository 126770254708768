
import {
  IonApp,
  IonRouterOutlet,
  IonSpinner,
} from '@ionic/vue';

import { defineComponent, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import firebase from 'firebase';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSpinner,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loading = ref(true);

    onBeforeMount(() => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (!user) {
          await router.push('/login');
        } else if (route.path === '/login') {
          await router.push('/');
        }

        loading.value = false;
      });
    });

    return {
      loading,
    };
  },
});
