<template>
  <IonApp>
    <ion-router-outlet v-if="!loading" />
    <div v-else class="loading-wrapper">
      <ion-spinner name="crescent" color="primary" />
    </div>
  </IonApp>
</template>

<script lang="ts">
import {
  IonApp,
  IonRouterOutlet,
  IonSpinner,
} from '@ionic/vue';

import { defineComponent, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import firebase from 'firebase';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSpinner,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loading = ref(true);

    onBeforeMount(() => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (!user) {
          await router.push('/login');
        } else if (route.path === '/login') {
          await router.push('/');
        }

        loading.value = false;
      });
    });

    return {
      loading,
    };
  },
});
</script>

<style scoped>
  .loading-wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
  .loading-wrapper ion-spinner {
    width: 4em;
    height: 4em;
  }
</style>
